import { Container, Row, Col } from "react-bootstrap"
import {isWebpSupported} from 'react-image-webp/dist/utils';

export const Gallery = () => {
    const alt = "Transport Pasageri Moldova Paris";

    const photos = [
        { src: require('./1.png'), webp: require('./1.webp'), alt: alt },
        { src: require('./2.png'), webp: require('./2.webp'), alt: alt },
        { src: require('./3.png'), webp: require('./3.webp'), alt: alt },
        { src: require('./4.png'), webp: require('./4.webp'), alt: alt },
        { src: require('./5.png'), webp: require('./5.webp'), alt: alt },
        { src: require('./6.png'), webp: require('./6.webp'), alt: alt },
        { src: require('./7.png'), webp: require('./7.webp'), alt: alt },
        { src: require('./8.png'), webp: require('./8.webp'), alt: alt },
        { src: require('./9.png'), webp: require('./9.webp'), alt: alt },
        { src: require('./10.png'), webp: require('./10.webp'), alt: alt },
        { src: require('./11.png'), webp: require('./11.webp'), alt: alt },
        { src: require('./12.png'), webp: require('./12.webp'), alt: alt },
    ];

    return (
        <Container className="px-4 py-5">
            <h2 className="pb-2 border-bottom">Galerie foto</h2>

            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
                {photos.map((photo, index) => (
                    <Col key={index} className="d-flex align-items-start">
                        {isWebpSupported()
                        ? <img src={photo.webp} alt={photo.alt} className="img-fluid img-thumbnail" />
                        : <img src={photo.src} alt={photo.alt} className="img-fluid img-thumbnail" />
                        }
                    </Col>
                ))}
            </Row>
        </Container>
    );
};