import { ReactComponent  as Logo } from './logo.svg';
import Image from './paris.webp';
import { Container, Row, Col } from "react-bootstrap"

export const Hero = ({title, subtitle}) => {
    
    return (
        <Container>
            <Row>
                <Col>
                    <div className="p-5 my-5 bg-light rounded-3"
                    style={{
                        backgroundImage: `url(${Image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center bottom',
                        overflow: 'hidden',
                    }}
                    >
                        <Logo className="d-block mx-auto mb-4" width={120} />
                        <h1 className="display-5 fw-bold text-center">
                            {title ? title : "Transport Pasageri Moldova - Paris"}
                        </h1>
                        
                        <p className="fs-4 text-center">
                            {subtitle ? subtitle : "Etno Lines este o compania de transport care operează curse spre Paris și oraşele din sudul Franţei, oferind servicii de cea mai înaltă calitate."}
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}