import { ReactComponent as Logo } from '../Hero/logo.svg';
import { Container, Col } from "react-bootstrap"
import { BsFacebook, BsInstagram } from "react-icons/bs";

export const Footer = () => {
    return (
        <Container>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <Col className="col-md-4 d-flex align-items-center">
                    <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        <Logo width={20} />
                    </a>
                    <span className="mb-3 mb-md-0 text-muted">© 2022 Etno Lines</span>
                </Col>

                <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li className="ms-3"><a className="text-muted" target="_blank" rel="noreferrer" href="https://www.facebook.com/etnolines.md"><BsFacebook /></a></li>
                    <li className="ms-3"><a className="text-muted" target="_blank" rel="noreferrer" href="https://www.instagram.com/etnolines.md/"><BsInstagram /></a></li>
                </ul>
            </footer>
        </Container>
    )
}