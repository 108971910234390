import { Features } from '../../components/Features/Features';
import { Destinations } from '../../components/Destinations/Destinations';
import { Gallery } from '../../components/Gallery/Gallery';
import { Contacts } from '../../components/Contacts/Contacts';
import { Hero } from '../../components/Hero/Hero';

export const Home = () => {
    return (
        <>
        <Hero 
            title="Transport Pasageri  Paris - Nice - Marseille" 
            subtitle="Etno Lines este o compania de transport care operează curse spre Paris și oraşele din sudul Franţei, oferind servicii de cea mai înaltă calitate."
        /> 
        <Destinations />
        <Features />
        <Contacts />
        <Gallery />
        </>
    );
}