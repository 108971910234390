import { BrowserRouter, Routes, Route } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Footer } from './components/Footer/Footer'
import { Home } from './pages/Home/Home'
import { Paris } from './pages/Paris/Paris'
import { Nice } from './pages/Nice/Nice'
import { Success } from './pages/Success/Success'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/paris" element={<Paris/>} />
        <Route path="/nice" element={<Nice/>} />
        <Route path="/success" element={<Success/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
