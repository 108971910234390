import { Gallery } from '../../components/Gallery/Gallery';
import { Features } from '../../components/Features/Features';
import { Container, Row, Col } from "react-bootstrap"

import Image from './success.gif';

export const Success = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col className="text-center">
                        <img src={Image} alt="Success" width="100" />
                        <div className="alert alert-success text-center" role="alert">
                            Vă mulțumim! Cererea de rezervare efectuată cu succes! În curînd revenim cu un apel!
                        </div>
                    </Col>
                </Row>
            </Container>
            <Features />
            <Gallery />
        </>
    );
}