import { OrderForm } from '../../components/Form/Form';
import { Hero } from '../../components/Hero/Hero';
import { Features } from '../../components/Features/Features';
import { Contacts } from '../../components/Contacts/Paris';
import { Gallery } from '../../components/Gallery/Gallery';

export const Paris = () => {
    return (
        <>
        <Hero 
            title="Transport Pasageri  Moldova - Paris"  
            subtitle="Etno Lines este o compania de transport care operează curse din Moldova spre Paris, oferind servicii de cea mai înaltă calitate."
        />
        <OrderForm />
        <Features />
        <Contacts />
        <Gallery />
        </>
    );
}