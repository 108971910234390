import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { Container, Card, Row, Col, Form, InputGroup, FormControl, Button, Alert } from "react-bootstrap"
import {makePostRequest} from '../../utils/telegram'
import Image from './cta.webp';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ro from 'date-fns/locale/ro';
registerLocale('ro', ro)

export const OrderForm = () => {
    const navigate = useNavigate();
    const [response, setResponse] = useState(false);
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const { register, reset, formState: { errors }, handleSubmit } = useForm();

    const telegramBotKey = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const chat_id = process.env.REACT_APP_TELEGRAM_USER_ID;

    const sendNotification = async (text, parse_mode) => {
        const url = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
        const details = {
            chat_id,
            text,
            parse_mode
        };  
        const response = await makePostRequest(url, details);
        return response;
    };
    
    const onSubmit = async (data) => {

        const body = {
            name: data.name,
            phone: data.phone,
            from: data.from,
            to: data.to,
            passengers: data.passengers,
            date: startDate.toLocaleDateString('ro-RO'),
        }

        
            const html = `
-------------------
Rezervare Noua 🕔
Nume: ${body.name}
Telefon: ${body.phone}
Data de Plecare: ${body.date}
Oraș de plecare: ${body.from}
Oraș de sosire: ${body.to}
Pasageri: ${body.passengers}
-------------------
        `;

        // send notification
        sendNotification(html, 'html');

        // set response to true
        setResponse(true);

        reset();
        setShow(true);
        navigate('/success');
    }

    return (
        <Container id="form">
            <Card className="shadow p-0 mt-5 mb-5 bg-body rounded">
                <Card.Body className="p-0">
                    <Row>
                        <Col xs={12} md={4} style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'right top',
                            overflow: 'hidden',
                        }}>
                        </Col>
                        <Col xs={12} md={8} className="p-5">
                            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="pb-1 mb-4 border-bottom">Rezervă un loc</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicFrom">
                                            <Form.Label>Oraș de plecare</Form.Label>
                                            <FormControl
                                                placeholder="Introduceți orașul de plecare"
                                                aria-label="Introduceți orașul de plecare"
                                                required
                                                {...register("from", { required: true, minLength: 3 })}
                                            />
                                            {errors.from?.type === 'required' && "Orașul de plecare este obligatoriu"}
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicTo">
                                            <Form.Label>Oraș de sosire</Form.Label>
                                            <FormControl
                                                placeholder="Introduceți orașul de sosire"
                                                aria-label="Introduceți orașul de sosire"
                                                required
                                                {...register("to", { required: true, minLength: 3 })}
                                            />
                                            {errors.to?.type === 'required' && "Orașul de sosire este obligatoriu"}
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicDate">
                                            <Form.Label>Data de plecare</Form.Label>
                                            <DatePicker
                                                selected={startDate} 
                                                dateFormat="dd/MM/yyyy"
                                                locale="ro" 
                                                className="form-control" 
                                                onChange={(date) => setStartDate(date)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicPassengers">
                                            <Form.Label>Număr de locuri</Form.Label>
                                            <FormControl
                                                aria-label="Număr de locuri"
                                                aria-describedby="basic-addon2"
                                                placeholder="Număr de locuri"
                                                className="form-control"
                                                type="number"
                                                defaultValue="1"
                                                {...register("passengers", { required: true, minLength: 1, maxLength: 10 })}
                                            />
                                            {errors.passengers?.type === 'required' && "Numărul de locuri este obligatoriu"}
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicName">
                                            <Form.Label>Nume Prenume</Form.Label>
                                            <FormControl
                                                placeholder="Cojocari Ion"
                                                aria-label="Cojocari Ion"
                                                required
                                                {...register("name", { required: true, minLength: 5 })}
                                            />
                                            {errors.name?.type === 'required' && "Introduceți numele dumneavoastră"}
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Telefon de contact</Form.Label>

                                            <InputGroup>
                                                <InputGroup.Text>+</InputGroup.Text>
                                                <FormControl
                                                    placeholder="37378555373"
                                                    aria-label="37378555373"
                                                    type="tel"
                                                    {...register("phone", { required: true, minLength: 8 })}
                                                />
                                                {errors.phone?.type === 'required' && "Introduceți numărul de telefon"}
                                            </InputGroup>

                                        </Form.Group>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="d-grid gap-2 mt-2 mt-md-0">
                                            <Button variant="success" type="submit">
                                                Trimite
                                            </Button>
                                        </div>
                                    </Col>
                                    
                                    {response && (
                                        <Alert variant="warning" className="mt-4 mt-md-3" onClose={() => setShow(false)} dismissible>
                                            Vă mulțumim! Cererea de rezervare efectuată cu succes!. În curînd revenim cu un apel!
                                        </Alert>
                                    )}

                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}