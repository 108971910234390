import { Container, Row, Col, Card } from "react-bootstrap"
import { FaViber, FaWhatsapp } from "react-icons/fa";
import classNames from 'classnames';
import styles from './Contacts.module.css';


export const Contacts = () => {

    const destination = [
        {
            "title": "Rezervă un bilet Moldova - Paris",
            "tur": "Miercuri și Vineri",
            "retur": "Vineri și Duminică",
            "phone_1": "+33 75 29 39 111",
            "phone_1_c": "tel:+33752939111",
            "phone_2": "+373 78 555 373",
            "phone_2_c": "tel:+37378555373",
            "viber": "viber://chat?number=37378555373",
            "whatsapp": "https://api.whatsapp.com/send?phone=+37378555373",
        },
        {
            "title": "Rezervă un bilet Coasta de Azur",
            "tur": "Miercuri și Vineri",
            "retur": "Vineri și Duminică",
            "phone_1": "+33 754 56 99 11",
            "phone_1_c": "tel:+33754569911",
            "phone_2": "+373 78 393 373",
            "phone_2_c": "tel:+37378393373",
            "viber": "viber://chat?number=37378393373",
            "whatsapp": "https://api.whatsapp.com/send?phone=+37378393373",
        }
    ]

    return (
        <Container id="contacts">
            <Card className="shadow p-5 bg-body rounded">
                <Card.Body>
                    <h2 className="pb-2 border-bottom">Rezervă un loc Moldova - Franța</h2>
                    <Row className="row-cols-1 row-cols-sm-2 g-4 py-5">
                        {destination.map((item, index) => (
                        <Col key={index}>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <p className="card-text">Tur: {item.tur}</p>
                                    <p className="card-text">Retur: {item.retur}</p>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <a href={item.phone_1_c} className="btn btn-sm btn-primary d-block">FR: {item.phone_1}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <a href={item.phone_2_c} className="btn btn-sm btn-primary d-block">MD: {item.phone_2}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <a href={item.viber} className={classNames(styles.viber, 'btn', 'btn-sm', 'btn-primary', 'd-block')}><FaViber /> Viber</a>
                                    </li>
                                    <li className="list-group-item">
                                        <a href={item.whatsapp} className={classNames(styles.whatsapp, 'btn', 'btn-sm', 'btn-primary', 'd-block')}><FaWhatsapp /> WhatsApp</a>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}