import { Container, Row, Col } from "react-bootstrap"
import classNames from 'classnames';
import styles from './Features.module.css';

import { BiBus, BiPlug } from "react-icons/bi";
import { IoRestaurantOutline, IoWifiSharp } from "react-icons/io5";
import { MdOutlineEmojiFoodBeverage, MdOutlineSmartDisplay, MdLuggage } from "react-icons/md";
import { ImWoman } from "react-icons/im";

export const Features = () => {
    return (
        <Container className="px-4 py-5">
            <h2 className="pb-2 border-bottom">Servicii incluse în preț</h2>

            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
                <Col className="d-flex align-items-start">    
                    <BiBus className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">Autocare Lux</h3>
                        <p>Autocare cu nivel sporit de confort, destinate pentru distanțe mari.</p>
                    </div>
                </Col>
                <Col className="d-flex align-items-start">
                    <IoRestaurantOutline className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">Prânz la restaurant ️</h3>
                        <p>Oferim 2 mese calde la restaurant.</p>
                    </div>
                </Col>
                <Col className="d-flex align-items-start">
                    <ImWoman className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">Însoțitoare de bord</h3>
                        <p>Vă stă la dispoziția dvs. pe tot parcurul călătoriei.</p>
                    </div>
                </Col>
                <Col className="d-flex align-items-start">
                    <MdOutlineEmojiFoodBeverage className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">Ceai, Cafea</h3>
                        <p>Pe tot parcursul călătoriei vă servim cu Ceai, Cafea, snackuri.</p>
                    </div>
                </Col>
                <Col className="d-flex align-items-start">
                    <IoWifiSharp className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">WiFi</h3>
                        <p>Internet WiFi de viteză înaltă pe toată perioada călătoriei.</p>
                    </div>
                </Col>
                <Col className="d-flex align-items-start">
                    <MdOutlineSmartDisplay className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">Multimedia</h3>
                        <p>Sistem multimedia individual pentru fiecare pasager.</p>
                    </div>
                </Col>
                <Col className="d-flex align-items-start">
                    <BiPlug className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">Priză</h3>
                        <p>Priză 220V individuală pentru fiecare pasager.</p>
                    </div>
                </Col>
                <Col className="d-flex align-items-start">
                    <MdLuggage className={classNames(styles.bi, 'text-muted', 'flex-shrink-0', 'me-3')} />
                    <div>
                        <h3 className="fw-bold mb-0 fs-4">Bagaj Gratuit</h3>
                        <p>50 Kg bagaj cu ridicare de la Domiciliu din Paris și Nice</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};