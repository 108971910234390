import { Container, Row, Col, Card } from "react-bootstrap"
import {isWebpSupported} from 'react-image-webp/dist/utils';

export const Destinations = () => {
    return (
        <Container>
            <Row className="row-cols-1 row-cols-md-4">
                <Col>
                    <Card className="mb-4">
                        
                        {isWebpSupported()
                            ? <img src={require('./1.webp')} alt="Transport Pasageri Moldova Paris" className="card-img-top" />
                            : <img src={require('./1.png')} alt="Transport Pasageri Moldova Paris"  className="card-img-top" />
                        }

                        <div className="card-body">
                            <h5 className="card-title">Moldova - Paris</h5>
                            <p className="card-text">Miercuri și Vineri</p>
                            <a href="/paris" className="btn btn-success d-block">Rezervă un loc</a>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card className="mb-4">
                        {isWebpSupported()
                            ? <img src={require('./2.webp')} alt="Transport Pasageri Paris Moldova" className="card-img-top" />
                            : <img src={require('./2.png')} alt="Transport Pasageri Paris Moldova"  className="card-img-top" />
                        }

                        <div className="card-body">
                            <h5 className="card-title">Paris - Moldova</h5>
                            <p className="card-text">Vineri și Duminică</p>
                            <a href="/paris" className="btn btn-success d-block">Rezervă un loc</a>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card className="mb-4">
                        {isWebpSupported()
                            ? <img src={require('./3.webp')} alt="Transport Pasageri Moldova - Marseille, Nice" className="card-img-top" />
                            : <img src={require('./3.png')} alt="Transport Pasageri Moldova - Marseille, Nice"  className="card-img-top" />
                        }

                        <div className="card-body">
                            <h5 className="card-title">Moldova - Marseille, Nice</h5>
                            <p className="card-text">Miercuri și Vineri</p>
                            <a href="/nice" className="btn btn-primary d-block">Rezervă un loc</a>
                        </div>
                    </Card>
                </Col>
                <Col>
                    <Card className="mb-4">
                        {isWebpSupported()
                            ? <img src={require('./4.webp')} alt="Transport Pasageri Marseille, Nice - Moldova" className="card-img-top" />
                            : <img src={require('./4.png')} alt="Transport Pasageri Marseille, Nice - Moldova"  className="card-img-top" />
                        }

                        <div className="card-body">
                            <h5 className="card-title">Marseille, Nice - Moldova</h5>
                            <p className="card-text">Vineri și Duminică</p>
                            <a href="/nice" className="btn btn-primary d-block">Rezervă un loc</a>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}